<template>
  <div>
    <b-alert
      :variant="alertVariant"
      :show="dismissCountDown"
      dismissible
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
    <div class="alert-body">
        <span><strong>{{this.alertMessage}}</strong></span>
      </div>
    </b-alert>
    <b-form @submit.prevent>
      <b-row>
        <b-col xl="6">
          <div class="col-12 mb-3">
            <b-form-group
              label="Nombre"
              label-for="name"
            >
              <b-form-input
                id="name"
                :value="user.name"
                v-model="user.name"
              />
            </b-form-group>
          </div>
          <div class="col-12 mb-3">
            <b-form-group
              label="Username"
              label-for="username"
            >
              <b-form-input
                id="username"
                :value="user.username"
                v-model="user.username"
              />
            </b-form-group>
          </div>

          <div class="col-12 mb-3">
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                :value="user.email"
                v-model="user.email"
              />
            </b-form-group>
          </div>
          <div class="col-12 mb-3">

 <b-form-group label="Role">
      <b-form-radio-group
      id="role"
      name="role"
                :value="user.role"
        v-model="user.role"
        aria-controls="ex-disabled-readonly"
      >
        <b-form-radio value="admin" v-model="user.role">
          Admin
        </b-form-radio>
        <b-form-radio value="moderator" v-model="user.role">
          Moderator
        </b-form-radio>
        <b-form-radio value="master" v-model="user.role">
          Master
        </b-form-radio>
        <b-form-radio value="chat-attendant" v-model="user.role">
          Atendente de chat
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>            
          </div>
          <div class="col-12 mb-3">
            <b-form-group
              label="Password"
              label-for="password"
            >
              <b-form-input type="password"
                id="password"
                :value="user.password"
                v-model="user.password"
              />
            </b-form-group>
          </div>

          <div class="col-12 mb-3">
            <b-form-group
              label="Status"
              label-for="status"
            >
             <b-form-checkbox checked="true" class="custom-control-primary" id="status"
                :value="user.status"
                v-model="user.status" name="check-button" switch />
            </b-form-group>
          </div>          
        </b-col>
       
      </b-row>
      <b-row>
        <b-col md="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            v-on:click="create()"
          >
            Criar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
  
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormRadio, BForm, BButton, BAlert,BFormDatepicker,BFormTimepicker, BDropdown, BDropdownItem, BFormCheckbox, BFormCheckboxGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BForm,
    BButton,
    BFormDatepicker,
    BFormTimepicker, BDropdown, BDropdownItem,
    BFormCheckbox, BFormCheckboxGroup
  },
  directives: {
    Ripple,
  },
  data () {
    return {
      user:{
        "name": "",
        "username" : "",
        "email": "",
        "role":"",
        "status":1
      },
      alertVariant: 'success',
      alertMessage: ''
    }
  },
  created() {
    console.log(this)
  },
  methods: {
    
    create(){
      if(this.user.name == '' || this.user.username == ''){

this.$toast({
            component: ToastificationContent,
            props: {
              title: "Userio deve ser preenchida minimamente!",
              icon: 'EditIcon',
              variant: 'error',
            },
          })
          return;
      }       
      console.log(this.user)
      this.$http
        .post('/user/insert',this.user)
        .then(res => {
          console.log(res.data)
this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          })
    }
  },
}
</script>

<style>

</style>
